import React, { useMemo, useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import { AgentContextProvider } from './components/AgentContextProvider';
import { ContactContextProvider } from './components/ContactContextProvider';
import Login from "./components/Login/Login";
import { clientId, auth_url, redirectUri, signInUrl } from './config.js';
import {auth} from './services/models/auth.js'
import jwt from 'jwt-decode'

export let token;
const App = () => {


  const Dash = useMemo(() => Dashboard, []);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [idToken, setIdToken] = useState('');
  const [groups, setGroups] = useState([]);
  let search = window.location.search;
  let codeparam = new URLSearchParams(search);
  let code = codeparam.get('code');
  window.history.replaceState({}, document.title, "/")
 
 
 function login() {
   if (code === null || code == 'undefined') {
     window.location.href = signInUrl
   } else {

       auth.authpost({grant_type:'authorization_code', client_id:clientId, code: code, redirect_uri: redirectUri})
       .then((res) => {

        let idToken = JSON.stringify(res.data.id_token);
        setIdToken(res.data.id_token)
        setGroups(jwt(idToken)['cognito:groups'])
         // let access_token = data.access_token
         // accessToken = access_token
         // localStorage.setItem("idToken", JSON.stringify(token))
         // localStorage.setItem("accessToken", JSON.stringify(accessToken))
       });
   } 

   


  
  }

   useEffect(() => {
  login()

  }, [])

  useEffect(() => {
   setIsSignedIn(true);
   console.log(">>>  App.jsx idToken = ");
   token = idToken
  }, [idToken]) 

 
  return (
    <div className='mainApp'>
      <Router>
        <Switch>
          <AgentContextProvider>
            <ContactContextProvider>
              {(isSignedIn === true) ? <Route exact path="/" render={props => <Dashboard idToken={idToken} groups={groups} />} /> : <Login />} 
            
            </ContactContextProvider>
          </AgentContextProvider>
        </Switch>
      </Router>
    </div>

  );
};


export default App;
